App {
  text-align: center;
  display: flex;
}

.navfirstparent {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000; /* High z-index to keep the navbar on top */
}

.nav-button {
  padding: 10px;
  margin: 1px;
  background: none;
  color: white;
  border: none;
  font-size: 25px;
  font-weight: bold;
  border-radius: 0;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; /* Black outline */
}

.menu-content {
  display: block;
  width: 100%;
  top: 50px; /* Adjust based on your layout */
  background-image: url('GothamSonicPic3.png'); 
  background-size: 150% auto;
  background-position: 15% 15%;
  background-repeat: no-repeat;
  text-align: left;
}

.hamburger {
  margin-top: 20px; 
  
}

.horizontal-line {
  height: 10px; /* Thickness of the line */
  background-color: black; /* Color of the line */
  width: 100%; /* Makes the div span the full width of the viewport */
  position: relative; /* Ensures it's in the normal flow of the document, can be changed based on layout needs */
}

@media (max-width: 768px) {
  .hamburger {
    margin-top: 0px;
    display: block;
    position: fixed; /* Fixed might work better for your layout */
    right: 10px;
    top: -20px; /* Adjust as needed */
    font-size: 80px;
    color: black;
    cursor: pointer;
    background-color: transparent;
}

.menu-content {
  margin-top: 0vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  background-image: url('GothamSonicPic8.png'); 
}

.nav-button {
  text-shadow: none;
}
.menu-content .nav-button {
  display: block;
  background: none;
  color: black;
  width: auto;
  height: auto;
  padding:7px;
  border: none;
  font-size: 19px;
  font-weight: 900; 
  border-radius: 0;
  margin: 1px;
  
}

#loginBtn {
  grid-column-start: 1;
  grid-column-end:   1;
  grid-row-start: 4;
  grid-row-end: 4;
}

#profileBtn {
  grid-column-start: 1;
  grid-column-end:   1;
  grid-row-start: 5;
  grid-row-end: 5;
}

#uploadBtn {
  grid-column-start: 2;
  grid-column-end:   2;
  grid-row-start: 4;
  grid-row-end: 4;
}


#echoBtn {
  grid-column-start: 2;
  grid-column-end:   2;
  grid-row-start: 5;
  grid-row-end: 5;
}

#registerBtn {
  
  grid-column-start: 2;
  grid-column-end:   2;
  grid-row-start: 3;
  grid-row-end: 3;
}

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.date-selector {
  margin: 5px;
  font-size: 1.3rem;
  display: grid;
  grid-template-columns: repeat(11, 1fr); 

  
}

.button {
  height: 50px; /* Example height, adjust as needed */
  grid-column: span 1;
 
}

.button:nth-child(8) {
  /*grid-column-start: 3; */
  grid-column: span 2; /* Make it span two columns */
}

.button:nth-child(9) {
  /*grid-column-start: 5; */
  grid-column: span 2; 
}

@media (max-width: 768px) {
.date-selector {
  margin: 5px;
  display: grid;
  grid-template-columns: repeat(10, 1fr); 
}

.button {
  height: 50px; /* Example height, adjust as needed */
  grid-column: span 2;
}

.button:nth-child(8) {
  grid-column: span 3; /* Make it span two columns */
}

.button:nth-child(9) {
  grid-column: span 3; 
}

}

.App-header {
  display: flex;
}

@media (max-width: 768px) {

  .App-header {
    background-color: transparent;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

}


.App-link {
  color: #61dafb;
}

body {
  background-image: url('./ramonesimage1png.png'), url('./punkclub.jpeg'), url('./cbgbspng.png');
  background-size: cover, cover, cover;
  background-position: top, top, bottom;
  background-repeat: no-repeat, no-repeat, repeat;
  opacity: 0.73; 
}

.show-list {
  display: grid;
  font-size: 20px;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  gap: 10px; /* Space between shows */
}

.concert-calendar {
  position: fixed; /* or 'fixed' if you want it to stay in view while scrolling */
  top: 100px;;  /* Adjust these values as needed */
  left: 35%;
  transform: translate(-40%);
  background-color: rgba(0, 0, 0, 0.5);
  /*background-color: #333;  */
  color: white;
  z-index: 2; /* Lower than the navbar */
  font-size: 2rem;
  padding: 10px;
  /*border-radius: 5px;*/
  width: 80%; /* or any specific width */
  max-width: 1300px; /* or any max-width */
  max-height: 80vh; 
  font-family: 'Menlo', 'Consolas', monospace;
  overflow-y: auto; 
}

h3 {
  margin-top: -3px;
  margin-bottom: 4px;
  color: white;
}

@media (max-width: 768px) {
  .concert-calendar {
    position: absolute;
    top: 90vw;  
    left: 3%;
    transform: none;
    background-color: #333;
    width: 90%;
    color: white;
    z-index: 2;
    font-family: 'Menlo', 'Consolas', monospace;
    font-size: 20px;
    font-weight: bold;
    overflow-y: hidden;
    max-height: none; 
  }

  h3 {
    margin-top: -3px;
    margin-bottom: 4px;
    color: white;
  }

  .show-list {
    grid-template-columns: 1fr; /* Adjust grid to single column for mobile */
  }
  
  .App-logo {
    height: 10vmin; 
  }
   
}

.headline {
  color: white;
  font-size: 40px;
  font-family: 'Menlo', 'Consolas', monospace;
  text-shadow: 2px 2px 4px #000000; 
}


/* ✅ Ensure we completely hide the logo-container when in Song Mode */
.hide-logo .logo-container {
  display: none !important;
  background-image: none !important;
}

.logo-container {
  
/*  position: fixed;
  top: 0vh;
  left: 0;
  width: 100vw;
  height: 20vh;    
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./GothamSonicPic3.png');
  background-position: center center;
  background-size: cover;  
  background-repeat: no-repeat;
  overflow: hidden;*/
}
@media (max-width: 768px){

.logo-container {
    position: fixed;
    top: 0vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;  
    background-image: url('./GothamSonicPic6.png'); /* Using the cropped image */
    background-size: contain; /* This will ensure the logo covers the available space without being distorted */
    background-position: center top; /* Centers the background image in the container */
    position: fixed;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Adjust the height to be proportional to the content */
     /* Position it lower or higher based on your visual needs */
    left: 0; /* Align left edge to the left edge of the viewport */
    /* Remove negative margin, adjust if needed */
    margin-left: 0; /* Ensure the logo is not unintentionally offset */
  }
  
}


.button {
  height: 60px;  /* Adjust this if your button heights are different */
  min-width: 50px;  /* Minimum width to ensure buttons are large enough */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2px;
  font-size: 1.30em;
  font-family: 'Menlo', 'Consolas', monospace;
  -webkit-appearance: none;  /* Removes default browser styling */
  border-radius: 0;  /* Keeps the keys square */
  background-color: #f0f0f0;  /* Light gray, typical of keyboard keys */
  border: 3px solid #ccc;  /* Subtle border for depth */
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);  /* Shadow for 3D effect */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);  /* Subtle text shadow for depth */
  transition: all 0.2s ease;  /* Smooth transition for interactions */
}



.button:active {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);  /* Smaller shadow for pressed effect */
  transform: translateY(2px);  /* Slight movement to mimic key press */
}


.weekday {
  color: blue; /* Day of the week color */
  font-weight: bold;
  font-size: 1em; /* Adjusted text size */
}

.date {
  color: green; /* Date color */
  font-size: 0.85em; /* Adjusted text size */
  font-weight: bold;
}

a {
  text-decoration: none;  /* No underline normally */
  color: inherit;  /* Inherits text color from parent, adjust as needed */
}

a:hover {
  text-decoration: underline;  /* Underline on hover for user feedback */
}

@media (min-width: 768px) {

  @keyframes fly-in-left-diagonal {
    from {
      transform: translate(-100vw, -100vh); /* Start from top-left off-screen */
      opacity: 0;
    }
    to {
      transform: translate(0, 0); /* End at the original position */
      opacity: 1;
    }
  }

  @keyframes fly-in-right-diagonal {
    from {
      transform: translate(100vw, -100vh); /* Start from top-right off-screen */
      opacity: 0;
    }
    to {
      transform: translate(0, 0); /* End at the original position */
      opacity: 1;
    }
  }

  @keyframes fly-in-bottom-left {
    from {
      transform: translate(-150vw, 150vh); /* Start from bottom-left off-screen */
      opacity: 0;
    }
    to {
      transform: translate(0, 0); /* End at the original position */
      opacity: 1;
    }
  }

  @keyframes fly-in-bottom-right {
    from {
      transform: translate(100vw, 100vh); /* Start from bottom-right off-screen */
      opacity: 0;
    }
    to {
      transform: translate(0, 0); /* End at the original position */
      opacity: 1;
    }
  }

  .animate-fly-in-left-diagonal {
    animation: fly-in-left-diagonal 1.5s ease-out forwards;
  }

  .animate-fly-in-right-diagonal {
    animation: fly-in-right-diagonal 1.5s ease-out forwards;
  }

  .animate-fly-in-bottom-left {
    animation: fly-in-bottom-left 1.5s ease-out forwards;
  }

  .animate-fly-in-bottom-right {
    animation: fly-in-bottom-right 1.5s ease-out forwards;
  }

}
