/* ✅ Ensure Song Mode gets its own background */

/* ✅ Full reset to remove ALL inherited styles from App.css */
.song-mode-root * {
  all: unset; /* 🚨 Wipes out everything (padding, margins, colors, etc.) */
  box-sizing: border-box; /* ✅ Resets box model */
  font-family: sans-serif; /* ✅ Default readable font */
}


.song-mode-root {
  background-image: url('./ramonesimage1png.png'); /* ✅ Set single background */
  background-size: cover;  /* ✅ Makes sure it fills the screen */
  background-position: center; /* ✅ Centers it nicely */
  background-repeat: no-repeat; /* ✅ No repeating */
  height: 100vh; /* ✅ Full viewport height */
  width: 100vw; /* ✅ Full viewport width */
  position: fixed; /* ✅ Ensure it doesn't move */
  top: 0;
  left: 0;
  z-index: -1; /* ✅ Keep it behind everything */
}

/* ✅ Optional: If you want a slight overlay effect */
.song-mode-root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* ✅ Adds a subtle dark overlay */
}
